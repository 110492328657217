import './AlbumHomePage.css'
import PhotoAlbum from "react-photo-album";
import { flickrHelper } from "../helpers/flickrHelper";
import { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css"
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";

function HomePage(props: any) {
    
    const [pictures, setPictures] = useState<any>({});
    const [index, setIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [exifData, setExifData] = useState<any>();
    
    const handleScroll = () => {
        var currY = window.scrollY + window.innerHeight;
        var maxY = document.body.scrollHeight;
        if (isLoading) {
            return
        }
        if (currY >= 0.9 * maxY) {
            getPictures()
        }
    }

    async function getPictures() {
        setIsLoading(true);
        try {
            setPictures(await flickrHelper.getAlbumPictures("-1"));
        } finally {
            setIsLoading(false)
        }
    }
    

    async function setDescription() {
        if (index >= 0) {
            try {
                if (pictures.newPageHigh[index].description != "") {
                    setExifData(await flickrHelper.getPictureExif(pictures.newPageHigh[index].id))
                }
            } finally {
            }
        }   
    }

    useEffect(() => {
        if (index >= 0){
            if (pictures.newPageHigh[index].id == exifData.id) {
                var description = "Camera: " + exifData.camera + "\nAperture: " + exifData.aperture + "\nShutter Speed: " + exifData.shutterSpeed + "\n" + (exifData.film != "" ? exifData.film : "ISO: " + exifData.iso) + "\nEV: " + exifData.ev
                pictures.newPageHigh[index].description = description
            }
        }

    }, [exifData])

    useEffect(() => {
        setDescription()
    }, [index])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [isLoading]);

    useEffect(() => {
        getPictures();
    }, []);


    function renderPhotos() {
        if (pictures.newPageLow === undefined) {
            return null
        }
        return(
            <>
                <PhotoAlbum 
                    photos={pictures.newPageLow} 
                    layout={props.isMobile ? "rows" : "masonry"}
                    targetRowHeight={500}
                    onClick={({index}) => setIndex(index)}
                    renderContainer= {({containerProps, children, containerRef}) => (
                        <div
                            style={{
                                paddingTop: "2rem",
                                paddingRight: "5rem",
                                paddingLeft: "5rem",
                                paddingBottom: "2rem",
                            }}
                        >
                            <div ref={containerRef} {...containerProps}>
                                {children}
                            </div>
                        </div>
                    )}/>
                <Lightbox
                    slides={pictures.newPageHigh}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                    plugins={[Zoom, Captions]}
                    index={index}
                    captions={{showToggle: true, descriptionMaxLines: 6, descriptionTextAlign: "center"}}
                    on={{view: ({index: currentIndex}) => setIndex(currentIndex)}}
                    carousel={{finite: true}}
                />
            </>
        )
    }

    return (
    <>
        <center>
            <h1 className="display-1"> Photo Library </h1>
            <h2> <small className="text-muted">Showcasing some of the recent pictures I taken</small></h2>
        </center>
        {renderPhotos()}
        {isLoading && <p>Loading...</p>}
    </>
    )
}


export default HomePage;